import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Toast, { ToastProps } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import { Designation, EProfessions, Practice } from 'types/Entitites';
import { addressSerializar } from 'utils/phoneUtils';
import BorderTableRow from 'components/Common/BorderTableRow';
import { useQuery } from 'react-query';
import { Box, Link, TextField, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ContentLoader from 'components/Common/ContentLoader';
import {
  fetchPlatformPracticeList,
  fetchPracticeProviderList,
} from 'pages/Dashboard/services/addpatient.services';
import ActionsMenu from 'pages/AdminPortal/Tabs/Practice/ActionMenu';

export default function PracticeList() {
  const { http } = useHttp();

  const [selectedPractice, setSelectedPractice] = React.useState({ id: '', name: '' });
  const [searchTerm, setSearchTerm] = React.useState('');
  const [typingTimeout, setTypingTimeout] = React.useState<NodeJS.Timeout | null>(null);
  const [toastProps, setToastProps] = React.useState<ToastProps | undefined>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const {
    data: practiceList,
    isFetching: practiceListFetching,
    refetch: refetchPracticeList,
  } = useQuery(['practices-list', page, rowsPerPage, searchTerm], {
    queryFn: fetchPlatformPracticeList(http.get, searchTerm ? `practice-name=${searchTerm}` : ''),
  });

  const {
    data: providersList,
    isFetching: providersListFetching,
    refetch: refetchProvidersList,
  } = useQuery(['providers-list', selectedPractice.id, page, rowsPerPage, searchTerm], {
    queryFn: fetchPracticeProviderList(http.get, selectedPractice.id, searchTerm ? `provider-name=${searchTerm}` : ''),
    enabled: !!selectedPractice.id,
  });

  const practiceListResponse = practiceList?.practices ?? [];
  const providersListResponse = providersList?.providers ?? [];

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastProps(undefined);
  };

  const onPracticeSelect = (practice: Practice) => {
    setSearchTerm('');
    setSelectedPractice(practice);
    setPage(0);
  };

  const onPracticeListSelect = () => {
    setSearchTerm('');
    setSelectedPractice({ id: '', name: '' });
    setPage(0);
  };

  React.useEffect(() => {
    if (selectedPractice.id) {
      refetchProvidersList();
    }
    else {
      refetchPracticeList();
    }
  }, [refetchPracticeList, refetchProvidersList, selectedPractice.id, page, rowsPerPage]);

  const breadcrumbs = [
    <Link underline='hover' onClick={onPracticeListSelect} sx={{ cursor: 'pointer' }}>
      Practice list
    </Link>,
    ...(selectedPractice.id
      ? [
        <Typography key='3' sx={{ color: 'text.primary' }}>
          {selectedPractice.name}
        </Typography>,
      ]
      : []),
  ];

  const tableCells = React.useMemo(() => {
    if (selectedPractice.id) {
      return ['Provider name', 'Provider email', 'Designation', 'Actions'];
    }

    return ['Practice name', 'Address', 'Added on', 'Actions'];
  }, [selectedPractice.id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value); // Update the state

    // Clear the previous timeout if it exists
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to debounce the search action
    const timeout = setTimeout(() => {
      setPage(0);
      selectedPractice.id
        ? refetchProvidersList()
        : refetchPracticeList(); // Perform search after debounce delay
    }, 300); // Delay of 500ms

    setTypingTimeout(timeout); // Save the timeout ID to clear it later
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const noData = selectedPractice.id
    ? providersListResponse.length === 0
    : practiceListResponse.length === 0;

  return (
    <>
      {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
      <Box display='flex' justifyContent='space-between' mb={3}>
        <Breadcrumbs separator='›' aria-label='breadcrumb'>
          {breadcrumbs}
        </Breadcrumbs>
        <TextField
          label={selectedPractice.id ? 'Search provider' : 'Search practice'}
          variant='outlined'
          fullWidth
          value={searchTerm}
          onChange={handleChange}
          margin='normal'
          sx={{ width: 300, m: 0 }}
        />
      </Box>
      <ContentLoader
        isFetching={practiceListFetching || providersListFetching}
        isError={false}
        minHeight={400}
        noDataText={noData ? `No ${selectedPractice.id ? 'provider' : 'practice'} found` : ''}
      >
        <>
          <TableContainer>
            <Table aria-label='provider-requests-table' size='small'>
              <TableHead>
                <TableRow>
                  {tableCells.map((cell) => (
                    <TableCell key={cell}>{cell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {selectedPractice.id ? (
                <TableBody>
                  {providersListResponse.map((provider) => (
                    <BorderTableRow key={`provider-practice-${provider.id}`}>
                      <TableCell>
                        {provider.firstName}
                        {' '}
                        {provider.lastName}
                      </TableCell>
                      <TableCell>{provider.email}</TableCell>
                      <TableCell>{EProfessions[provider.designation as Designation]}</TableCell>
                      <TableCell>
                        <ActionsMenu
                          toastProps={toastProps}
                          setToastProps={setToastProps}
                          practiceId={selectedPractice?.id}
                          providerId={provider.id}
                          providerEmail={provider.email}
                          refetchProvidersList={refetchProvidersList}
                        />
                      </TableCell>
                    </BorderTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  {practiceListResponse.map((practice) => (
                    <BorderTableRow key={`provider-practice-${practice.id}`}>
                      <TableCell>
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onPracticeSelect(practice)}
                          underline='hover'
                        >
                          {practice.name}
                        </Link>
                      </TableCell>
                      <TableCell>{addressSerializar(practice.address)}</TableCell>
                      <TableCell>
                        {new Date(practice.timeAudit?.createdAt as string).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <ActionsMenu
                          toastProps={toastProps}
                          setToastProps={setToastProps}
                          practiceId={selectedPractice?.id}
                        />
                      </TableCell>
                    </BorderTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={
              selectedPractice.id
                ? providersList?.providers?.length ?? 0
                : practiceList?.practices?.length ?? 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </ContentLoader>
    </>
  );
}
