import React, { ReactNode } from 'react';
import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { auth } from 'utils/spaUrls';
import useLocalStorage from 'hooks/use-local-storage';
import { CacheKeys } from 'utils/constants';
import { UserRole } from 'types/NavTreeModel';

const jarvisLoginPath = '/auth/jarvis-login';
const landingPadUri = `${window.location.origin}${auth.landingPad}`;

export default function Auth0ProviderWithHistory({ children }: { children: ReactNode }) {
  const [loginPathname, setLoginPathname] = useLocalStorage(CacheKeys.loginPathname);

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const connection = window.location.pathname === jarvisLoginPath
   || loginPathname === jarvisLoginPath
    ? 'admin-authentication'
    : 'provider-authentication';
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    const roles = user?.['headlamp/roles'] || [];
    if (roles.includes(UserRole.CHIEF_ADMIN)) {
      setLoginPathname(jarvisLoginPath);
    }
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      audience={audience}
      clientId={clientId}
      domain={domain}
      connection={connection}
      onRedirectCallback={onRedirectCallback}
      redirectUri={landingPadUri}
    >
      {children}
    </Auth0Provider>
  );
}
